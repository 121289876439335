import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-scroll";
import Button from "./Button";
import logo from "../images/Creditper-Logo.svg";
import phoneCall from "../images/phone-call.svg";
import { creditPerPhoneNo, mobileAppPlayStoreLink } from "../creditPerDetails";
import { localStorageKeys } from "../utility/localstorage";
import { staticWebAppTextTranslation } from "../utility/constant";

const Hero = (props) => {
  const {
    headlineOne,
    headlineTwo,
    headlineThree,
    buttonLabel,
    rightColImage,
    myCustomAppState,
    setMyCustomAppState,
  } = props;

  const switchLanguage = () => {
    const switchingToLang = Object.keys(staticWebAppTextTranslation).find(
      (item) => item !== myCustomAppState?.language
    );
    localStorage.setItem(localStorageKeys.language, switchingToLang);
    setMyCustomAppState({ [localStorageKeys.language]: switchingToLang });
  };

  const onDownloadAppButtonClick = () => {
    window.fbq("track", "Download App Button");
    window.gtag("event", "Download App Button", {
      event_category: "Button",
      event_label: "Buttons",
    });
  };

  const onPhoneCallButtonClick = () => {
    window.fbq("track", "Phone Call Button");
    window.gtag("event", "Phone Call Button", {
      event_category: "Button",
      event_label: "Buttons",
    });
  };

  return (
    <section className="hero-banner">
      <Container>
        <Row>
          <Col lg={6} xl={6}>
            <div className="header-mobile">
              <div class="btn-container">
                <label class="switch">
                  <input
                    type="checkbox"
                    name="toogle-mobile"
                    id="toogle-mobile"
                    onClick={switchLanguage}
                  />
                  <label
                    for="toogle-mobile"
                    data-on="اردو"
                    data-off="En"
                    class="switch-inner"
                  ></label>
                </label>
              </div>

              <Link
                className="call-content nav-item"
                href={`tel:${creditPerPhoneNo}`}
                onClick={onPhoneCallButtonClick}
              >
                <Image className="phone-icon" src={phoneCall} fluid={true} />
                <div>{creditPerPhoneNo}</div>
              </Link>

              <Image className="logo" src={logo} />
              <p className="logo-text">
                Powered by CreditFix Financial Services Limited
              </p>
            </div>
            <div className="title-wrapper">
              <h1 className="content">{headlineOne}</h1>
              <h1 className="content">{headlineTwo}</h1>
              <h1 className="content" style={{ color: "#FF5400" }}>
                {headlineThree}
              </h1>
            </div>

            <a href={mobileAppPlayStoreLink}>
              <Button label={buttonLabel} onClick={onDownloadAppButtonClick} />
            </a>
          </Col>
          <Col className="col-right" lg={6} xl={6}>
            <Image className="image-wrapper" src={rightColImage} fluid={true} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
