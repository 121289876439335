import React from 'react';
import { Image } from 'react-bootstrap';
import Button from '../components/Button';
import storeIcon from '../images/store-icon.svg';
import cityIcon from '../images/city-icon.svg';
import personIcon from '../images/person-icon.svg';
import phoneIcon from '../images/phone-icon.svg';
import emailIcon from '../images/email-icon.svg';
import locationIcon from '../images/location-icon.svg';

const VendorCard = (props) => {
  return (
    <div className='vendor-card'>
      <div className='vendor-card__wrapper'>
        <Image className='vendor-card__icon' fluid={true} src={storeIcon} />
        <div className='vendor-card__name'>NJ Auto Industries PVT LTD</div>
      </div>
      <div className='vendor-card__wrapper'>
        <Image className='vendor-card__icon' fluid={true} src={cityIcon} />
        <div className='vendor-card__city'>Lahore</div>
      </div>
      <div className='vendor-card__wrapper'>
        <Image className='vendor-card__icon' fluid={true} src={personIcon} />
        <div className='vendor-card__contact-person'>Shahid Sharif</div>
      </div>
      <div className='vendor-card__wrapper'>
        <Image className='vendor-card__icon' fluid={true} src={phoneIcon} />
        <div className='vendor-card__mobile'>3331215970</div>
      </div>
      <div className='vendor-card__wrapper'>
        <Image className='vendor-card__icon' fluid={true} src={emailIcon} />
        <div className='vendor-card__email'>
          shahid.sharif@superpower.com.pk
        </div>
      </div>
      <div className='vendor-card__wrapper'>
        <Image className='vendor-card__icon' fluid={true} src={locationIcon} />
        <div className='vendor-card__address'>
          Pirani Tower, 75-C, 11th Commercial Street, DHA-Phase 2, Karachi
        </div>
      </div>
      <div className='vendor-card__location-btn-wrapper'>
        <Button label='Locate' onClick />
      </div>
    </div>
  );
};

export default VendorCard;
